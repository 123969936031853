import request from '@/utils/request'

// 列出所有逝者
export function findTenantServiceOrderDeathList(params) {
  return request({
    url: `/tenant/service_order_deaths`,
    method: 'get',
    params: params
  })
}

// 列出所有逝者
export function findSystemServiceOrderDeathList(params) {
  return request({
    url: `/system/service_order_deaths`,
    method: 'get',
    params: params
  })
}

// 逝者基本信息
export function findServiceOrderDeathBasicInfo(id) {
  return request({
    url: `/service_order_deaths/${id}/basicInfo`,
    method: 'get'
  })
}

// 逝者家属信息
export function findServiceOrderDeathFamilyInfo(id) {
  return request({
    url: `/service_order_deaths/${id}/familyInfo`,
    method: 'get'
  })
}

// 修改图片
export function updateServiceOrderDeathImg(id, data) {
  return request({
    url: `/tenant/service_order_deaths/${id}/img`,
    method: 'put',
    data
  })
}

// 修改视频
export function updateServiceOrderDeathVideo(id, data) {
  return request({
    url: `/tenant/service_order_deaths/${id}/video`,
    method: 'put',
    data
  })
}

// 修改证件
export function updateServiceOrderDeathDocument(id, data) {
  return request({
    url: `/tenant/service_order_deaths/${id}/document`,
    method: 'put',
    data
  })
}

// 修改生平简介
export function updateServiceOrderDeathLifeInfo(id, data) {
  return request({
    url: `/tenant/service_order_deaths/${id}/life_info`,
    method: 'put',
    data
  })
}

export function findDeathEditForm(id) {
  return request({
    url: `/tenant/service_order_deaths/${id}/edit`,
    method: 'get'
  })
}

export function updateDeathInfo(id, data) {
  return request({
    url: `/tenant/service_order_deaths/${id}`,
    method: 'put',
    data
  })
}

export function findChildrenOptions(id) {
  return request({
    url: `/tenant/service_order_deaths/${id}/children/options`,
    method: 'get'
  })
}

